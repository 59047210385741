.Community {
    color: #0044cc;
    padding: 20px;
}
.Community h1{
    color: #0044cc;
}
.search-bar {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
}

.button-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.button-container .btn {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
}

.button-container .btn.red {
    background-color: red;
}

.button-container .btn.green {
    background-color: green;
}

.button-container .btn.blue {
    background-color: blue;
}

.button-container .btn.purple {
    background-color: purple;
}

.map-container {
    height: 400px;
}

.map {
    height: 100%;
}

.form-popup {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    padding: 20px;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1000;
}

.form-popup h2 {
    margin-bottom: 20px;
}

.form-popup input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.form-popup button {
    width: 48%;
    padding: 10px;
    margin: 5px 1%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.form-popup button:nth-child(4) {
    background-color: red;
    color: white;
}

.form-popup button:nth-child(3) {
    background-color: green;
    color: white;
}

.tiny-map-container {
    height: 200px;
    margin-bottom: 10px;
}

.tiny-map {
    height: 100%;
}
