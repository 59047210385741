.contact-search-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.contact-search-container h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-family: 'Arial', sans-serif;
}

.search-bar {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1em;
}

.contacts-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contact-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.contact-item:hover {
    transform: translateY(-5px);
}

.contact-item h2 {
    font-size: 1.5em;
    color: #007bff;
    margin-bottom: 10px;
}

.contact-item p {
    color: #555;
    font-size: 1em;
    margin: 5px 0;
}
