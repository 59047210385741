@font-face {
  font-family: 'MyCustomFont';
  src: url('./fonts/SFPRODISPLAYREGULAR.OTF') format('opentype');
  font-weight: normal;
  font-style: normal;
}

h1{
  color: #e9efff;
  place-content: start;
}
/* Apply the custom font to the whole application */
body {
  font-family: 'MyCustomFont', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App-header {
  background-color: #0044cc;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.App-logo {
  width: 5vw;
  height: auto;
  border-radius: 500000px;
}

.search-input {
  padding: 8px;
  width: 50%;
  border: none;
  border-radius: 5px;
}

.App-nav {
  background-color: #007bff;
  padding: 10px 0;
  display: flex;
  justify-content: space-evenly;
  font-family: 'MyCustomFont', sans-serif;
  color: white;
}

.App-nav a {
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  font-weight: bold;
}

.nav-separator {
  border-left: 1px solid white;
  height: 20px;
  align-self: center;
}

.App-nav a:hover {
  background-color: #0056b3;
}

main {
  padding: 20px;
}

.logout-button {
  padding: 10px 20px;
  margin-left: 20px;
  background-color: #ff4c4c;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #ff0000;
}
