.survey-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.survey-container h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-family: 'Arial', sans-serif;
}

.survey-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.survey-card h2 {
    font-size: 1.5em;
    color: #007bff;
    margin-bottom: 10px;
}

.survey-card p {
    color: #555;
    font-size: 1em;
    margin-bottom: 20px;
}

.options {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.option-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
}

.option-button:hover {
    background-color: #0056b3;
}

.option-button:focus {
    outline: none;
}
