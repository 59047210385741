.auth-container {
    align-content: center;
    width: 300px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    text-align: center;
}

.auth-container h2 {
    margin-bottom: 20px;
}

.auth-container input,
.auth-container select {
    width: 90%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.auth-container .mayor-checkbox {
    margin-bottom: 10px;
}

.auth-container button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.auth-container button:hover {
    background-color: #0056b3;
}

.auth-container .error {
    color: red;
    margin-bottom: 10px;
}

/* Transition for fade-up effect */
.fade-enter {
    opacity: 0;
    transform: translateY(20px);
}

.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
}

.fade-exit {
    opacity: 1;
    transform: translateY(0);
}

.fade-exit-active {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 300ms, transform 300ms;
}
