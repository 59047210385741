.administrative-container {
    padding: 20px;
}

.administrative-container h1 {
    color: #0044cc;
    text-align: center;
    margin-bottom: 20px;
}

.top-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.column {
    width: 48%;
}

.latest-messages,
.notifications {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
}

.latest-messages h2,
.notifications h2,
.notifications h3 {
    margin-top: 0;
}

.message,
.notification,
.survey {
    margin-bottom: 10px;
}

.news-section {
    margin-bottom: 20px;
}

.news-section h2 {
    text-align: center;
    margin-bottom: 20px;
}

.news-grid {
    border-radius: 20px;
    padding: 1.5vw;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 2vw;
}

.news-article {
    height: 10vw;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.news-article p {
    margin-top: 0;
    font-size: larger;
    text-align: center;
}

.news-article img {
    border-radius: 20px;
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.news-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.news-popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 100%;
}

.news-popup-content img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.procedures-advancement {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
}

.procedures-advancement h2 {
    margin-top: 0;
}

.procedure {
    margin-bottom: 10px;
}
