.add-content-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    max-width: 600px;
    margin: 0 auto;
}

.add-content-container h2 {
    color: #ff4c4c;
    text-align: center;
    margin-bottom: 20px;
}

.add-content-container form {
    display: flex;
    flex-direction: column;
}

.add-content-container select,
.add-content-container input,
.add-content-container textarea,
.add-content-container button {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.add-content-container button {
    background-color: #ff4c4c;
    color: white;
    cursor: pointer;
}

.add-content-container button:hover {
    background-color: #ff0000;
}
